<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent width="900px">
			<v-form @submit.prevent="addOrUpdateUser" ref="entryForm">
				<v-card class="modal-con update-modal">
					<v-row class="modal-row">
						<v-col class="modal-col" cols="4" sm="6" md="6">
							<v-card-title class="p-0 modal-title-con">
								<h5 class="modal-title">{{ title }}</h5>
							</v-card-title>
						</v-col>

						<v-col class="modal-col" cols="8" sm="6" md="6">
							<v-tabs v-model="tab" class="float-right">
								<v-tab v-if="(true)" slim grow value="tabProfile" class="tabs_D_item together">Profile
								</v-tab>
								<v-tab slim grow value="tabTimestamp" :disabled="!(editUser && editUser?.UserID)"
									class="tabs_D_item together">Timezone
								</v-tab>
								<v-tab slim grow value="tabData" @click="isTabClicked()"
									:disabled="!(editUser && editUser?.UserID)" class="tabs_D_item together">Data
								</v-tab>
							</v-tabs>
						</v-col>
					</v-row>

					<v-card-text class="modal-content-con p-0">
						<v-window v-model="tab">
							<!-- Profile Tab Start-->
							<v-window-item class="tab-profile" value="tabProfile">
								<v-row class="modal-row">
									<v-col class="modal-col" cols="12" sm="6" md="6">
										<v-text-field label="First name*" :rules="nameRules_firstname"
											v-model="user.FirstName" variant="underlined" clearable
											:clear-icon="'mdi-close'"></v-text-field>
									</v-col>

									<v-col cols="12" sm="6" md="6" class="modal-col">
										<v-text-field label="Last name*" :rules="nameRules_lastname"
											v-model="user.LastName" variant="underlined" clearable
											:clear-icon="'mdi-close'"></v-text-field>
									</v-col>


									<v-col class="modal-col" cols="12" sm="6" md="6">
										<v-text-field class="phone-input-pupup" label="Phone number*"
											v-model="user.PhoneNumber" :rules="phoneRules" type="text"
											@keypress="isPhoneNumber($event)" variant="underlined" clearable
											:clear-icon="'mdi-close'">

											<vue-tel-input :value="PhoneNumber1" :onlyCountries="selectedCountries"
												@open="CountrySelectOpen" @close="CountrySelectClose"
												:input-id="'phone'" @input="onPhoneInput">
											</vue-tel-input>
											<span class="countryCodePopup" style="font-weight: 600;">+{{
												user.countryCode }}</span>
										</v-text-field>
									</v-col>

									<v-col v-if="source != 'profile'" cols="12" sm="6" md="6" class="modal-col">
										<v-text-field label="Email*" :rules="nameRules_email" v-model="user.Email"
											variant="underlined" clearable :clear-icon="'mdi-close'"></v-text-field>
									</v-col>




									<!-- <v-col cols="12" sm="6" md="6" v-if="source=='profile' && !serverError && Receiver == undefined && user.PhoneNumber != null &&
										validatePhoneNumber(1) !== true &&
										(this.$root.userdetail.user[0].PhoneNumber == null ||
											this.$root.userdetail.user[0].PhoneNumber == '')">
										<div class="v_notification">
											{{ notification_msg }}
										</div>
									</v-col> -->

									<!-- <v-col cols="12" sm="6" md="6" v-if="source=='profile' && !serverError && validatePhoneNumber(1) !== true">
										<div class="v_notification">
											{{ notification_msg }}
										</div>
									</v-col> -->

									<v-col cols="12" sm="6" md="6" v-if="serverError">
										<div class="servererror">{{ serverError }}</div>
									</v-col>
								</v-row>

								<v-row class="admin-operator-row" v-if="source != 'profile'">
									<v-col cols="12" sm="6" md="6">
										<div class="switch-group">
											<div class="switch" :class="{'disabled-overlay': isAdminEditingHimself}">
												<SwitchLg v-model="adminRoleSwitch" /> Admin
											</div>
											<div class="switch">
												<SwitchLg v-model="operatorRoleSwitch" /> Operator
											</div>
										</div>
									</v-col>
									<v-col cols="12" sm="6" md="6"
										v-if="isAddModal && !adminRoleSwitch && !operatorRoleSwitch">
										<div class="v_notification_role">
											Select the roles for this user.
										</div>
									</v-col>
									<v-col cols="12" sm="6" md="6"
										v-if="isAddModal && (adminRoleSwitch || operatorRoleSwitch)">
										<div style="display: block!important;" class="v_notification">
											<div v-if="operatorRoleSwitch">- This user can add Contacts and Messages
											</div>
											<div v-if="adminRoleSwitch">- This user can add and manage Operators.</div>
											<div>- Saving these entries sends an email invitation.</div>
										</div>
									</v-col>

									<v-col cols="12" sm="6" md="6"
										v-if="editUser && editUser.SaasOperator && !operatorRoleSwitch && editUser.data">
										<div style="display: block!important;" class="v_notification_attention">
											<div><b>Attention:</b> removing Operator permissions requires the transfer
												of their data to another Operator first.</div>
										</div>
									</v-col>
								</v-row>

							</v-window-item>
							<!-- Profile Tab End-->

							<!-- Timestamp Tab Start-->
							<v-window-item value="tabTimestamp">
								<div class="timez_p">
									<div class="tz_lable">This is for the Message timestamp your <i>Receivers</i> will
										see.</div>
									<VueSelect v-model="user.selectedTimezone" :options="tzObj"
										:rules="nameRules_timezone" placeholder="Select Timestamp" />

									<div class="v_notification notof_tze" v-if="!user.selectedTimezone">
										{{ timezoneError }}
									</div>

									<v-col cols="12" sm="6" md="6" v-if="serverError">
										<div class="servererror">{{ serverError }}</div>
									</v-col>
								</div>

							</v-window-item>
							<!-- Timestamp Tab End-->

							<!-- Data Tab Start-->
							<v-window-item value="tabData">
								<div class="data-grid-group">
									<div class="data-grid" v-if="dataTabData?.operator != undefined">
										<h3>Operators</h3>
										<DataTabOperator />
										<h6>{{ dataTabData?.operator ?? 0 }}</h6>
									</div>
									<div class="data-grid" v-if="dataTabData?.contact != undefined">
										<h3>Contacts</h3>
										<DataTabContacts />
										<h6>{{ dataTabData?.contact ?? 0 }}</h6>
									</div>
									<div class="data-grid" v-if="dataTabData?.group != undefined">
										<h3>Groups</h3>
										<DataTabGroup />
										<h6>{{ dataTabData?.group ?? 0 }}</h6>
									</div>
									<div class="data-grid" v-if="dataTabData?.message != undefined">
										<h3>Messages</h3>
										<DataTabMessage />
										<h6>{{ dataTabData?.message ?? 0 }}</h6>
									</div>
								</div>

							</v-window-item>
							<!-- Data Tab End-->

						</v-window>
						<!-- <small>*indicates required field</small> -->
					</v-card-text>


					<v-card-actions class="modal-actions p-0">
						<span class="email_c" v-if="editUser">
							{{ editUser?.Email }}
						</span>
						<v-spacer></v-spacer>

						<div class="button-group">
							<button class="action-btn text-btn font-roboto text-decoration-none text-uppercase"
								variant="text" @click="dialogClosed" plain
								:disabled="source == 'profile' && !this.user.PhoneNumber">
								CLOSE
							</button>

							<button class="action-btn text-btn text-uppercase" :class="{ disabled1: !showNextButton }"
								variant="text" type="submit" v-if="tab == 'tabProfile'" plain>
								<!-- @click="editUser && editUser.UserID ? tab = 'tabTimestamp' : null"  -->

								<span v-text="editUser && editUser?.UserID ? 'Save' : 'Save'"></span>
							</button>

							<!-- <button class="action-btn text-btn text-uppercase" :class="{ disabled1: !showNextButton }"
								variant="text" type="button" v-if="editUser && tab == 'tabTimestamp'"
								@click="tab = 'tabProfile'" plain>
								BACK
							</button> -->

							<button class="action-btn text-btn text-uppercase" :class="{ disabled1: !showSaveButton }"
								variant="text" type="submit"
								v-if="editUser && editUser?.UserID && tab == 'tabTimestamp'" plain>
								SAVE
							</button>

							<v-progress-circular v-if="loading" color="primary" indeterminate></v-progress-circular>
						</div>

					</v-card-actions>

				</v-card>
			</v-form>
		</v-dialog>
	</v-row>

	<!-- <AlertDialog v-model="adminFreeTrialError" @closed="adminFreeTrialError = false"
		title="Attention">
		<div>During this period of free usage, you can add up to two Admins.</div> <br>
		<div>To add more than two, activate your paid account now.</div> <br>
		<div>If you are not the Account Holder, request that they convert to a paid account.</div>
	</AlertDialog>

	<AlertDialog v-model="operatorFreeTrialError" @closed="operatorFreeTrialError = false"
		title="Attention">
		<div>During this period of free usage, you can add up to two Operators.</div> <br>
		<div>To add more than two, activate your paid account now.</div> <br>
		<div>If you are not the Account Holder, request that they convert to a paid account.</div>
	</AlertDialog> -->
</template>

<script>
import { ref } from 'vue';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import timezonelist from '../timezone.json'
import VueSelect from 'vue3-select-component'
import 'vue3-select-component/dist/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import DataTabOperator from '@/components/icons/DataTabOperator.vue';
import DataTabMessage from '@/components/icons/DataTabMessage.vue';
import DataTabGroup from '@/components/icons/DataTabGroup.vue';
import DataTabContacts from '@/components/icons/DataTabContacts.vue';

import { VDialog, VBtn, VCard, VRow, VCardTitle, VCardText, VTextField, VCardActions, VCol, VSpacer, VForm } from 'vuetify'
import SwitchLg from '@/components/common/SwitchLg.vue';
import userService from '@/services/userService';
import notifications from '@/helpers/notifications';
import AlertDialog from './modals/AlertDialog.vue';

const option = ref("");

export default {
	props: ['title', 'TogglePopup', 'ProfileSaved', 'Notification', 'Receiver', 'meg_notice', 'editUser', "source"],
	components: {
		VDialog, VCard, VRow, VCardTitle, VCardText, VTextField, VCardActions, VCol, VSpacer, VForm, VueTelInput, VueSelect, SwitchLg, DataTabOperator, DataTabMessage, DataTabGroup, DataTabContacts
	},
	computed: {
		PhoneNumber1: {
			get() {
				if (!this.user.countryCode) {
					if (this.user.PhoneNumber) {
						return "+" + this.user.PhoneNumber
					} else {
						return '';
					}
				} else {
					if (this.user.PhoneNumber) {
						return "+" + this.user.countryCode + this.user.PhoneNumber;
					} else {
						return '';
					}
				}
			},
			set(val) { }
		},
		showNextButton: {
			get() {
				let show = (!this.loading && this.user.FirstName && this.user.LastName && this.validatePhoneNumber(1) && this.validateEmail(this.user.Email));
				if (this.source != 'edit') {
					show = (show && (this.operatorRoleSwitch || this.adminRoleSwitch))
				} else {
					if (this.editUser?.data && !this.operatorRoleSwitch) {
						return false;
					}
				}
				return show;
			}
		},
		showSaveButton: {
			get() {
				return this.showNextButton && !!this.user?.selectedTimezone;
			}
		},
		isAddModal: {
			get() {
				return !this.editUser
			}
		},
		isAdminEditingHimself: {
			get(){
				return this.currentUser?.UserID==this.editUser?.UserID && this.currentUser?.SaasAdmin;
			}
		},
		currentUser: {
			get(){
				return this.$store.getters.user
			}
		}
	},

	beforeMount() {
		this.timezoneObject();
		this.defaultSelectedTimeZone();
	},
	mounted() {
		if (this.user.PhoneNumber == '') {
			this.isDisabledTab = true;
		} else {
			this.isDisabledTab = false;
		}
		if (this.editUser?.Phone && this.editUser?.Phone.length > 10) {
			this.PhoneNumber1 = "+" + this.editUser?.Phone;
		} else {
			this.user.PhoneNumber = this.editUser?.Phone;
		}
		if (!this.isAddModal) {
			this.getUserProfileData()
		}
	},
	data() {
		return {
			adminRoleSwitch: !!this.editUser?.SaasAdmin,
			operatorRoleSwitch: !!this.editUser?.SaasOperator,
			// adminFreeTrialError:false,
			// operatorFreeTrialError: false,
			dataTabData: {},
			loading: false,
			token: localStorage.getItem("tknds") || '',
			dialog: true,
			tabclicked: false,
			errors: {},
			serverError: "",
			tab: null,
			$reset: false,
			selectedTimezone: this.editUser?.timezone,
			timezoneError: 'Please select the timezone that your Receivers are in.',
			notification_msg: 'Please enter your phone number to preview and hear the messages you set, to make sure they will play correctly.',
			tz_list: timezonelist,
			userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			defaultSelectedTz: '',
			option: '',
			tzObj: [],
			btn_disable: 0,
			alertMessage: '',
			initialPhoneNumber: '',
			user: {
				FirstName: this.editUser?.FirstName,
				LastName: this.editUser?.LastName,
				Email: this.editUser?.Email,
				PhoneNumber: this.editUser?.Phone,
				UserID: this.editUser?.UserID,
				UserTable: '',
				countryCode: '',
				selectedTimezone: this.editUser?.timezone ?? timezonelist?.at(0)?.utc?.find(Boolean),
			},
			nameRules_firstname: [
				v => !!v || 'First name is required'
			],
			nameRules_lastname: [
				v => !!v || 'Last name is required'
			],
			nameRules_email: [
				v => !!v || 'Email field is required',
				v => /.+@.+\..+/.test(v) || 'Email must be valid'
			],
			nameRules_timezone: [
				v => !!v || 'Timezone is required'
			]
			,
			phoneRules: [
				v => !!v || 'Phone number is required',
				v => this.validatePhoneNumber(v)
			],
			selectedCountries: ['IN', 'JP', 'US', 'CA', 'PL', 'PH', 'UY', 'CH', 'CZ', 'RS', 'VN', 'PT', 'GB', 'NL', 'FR', 'HR', 'UA', 'ME']
		}
	},
	methods: {
		isTabClicked() {
			console.log("Timezone tab Clicked");
			this.tabclicked = true;
		},
		async getUserProfileData() {
			this.dataTabData = await userService.getUserProfileData(this.user?.UserID);
		},
		timezoneObject() {
			for (let todo of this.tz_list) {
				let obj = {}
				obj.label = todo.text
				obj.value = todo.utc[0]
				this.tzObj.push(obj);
			}
		},
		findUserTimezone(data) {
			if (JSON.stringify(data).indexOf(this.userTimezone) > -1) {
				return data;
			}
		},
		defaultSelectedTimeZone() {
			if (this.userTimezone != '') {
				this.defaultSelectedTz = this.tz_list.find(this.findUserTimezone); // return object			
			}
			if (this.defaultSelectedTz.text) {

				if (this.$root.userdetail.user[0].TimeZone == '' || this.$root.userdetail.user[0].TimeZone == undefined) {
					this.selectedTimezone = this.defaultSelectedTz.utc[0];
				} else {
					this.selectedTimezone = this.$root.userdetail.user[0].TimeZone;
				}
				//this.selectedTimezone = this.defaultSelectedTz.text;
			}
		},
		isActive(path) {
			return ref(this.$router.currentRoute).value.path === path
		},
		async addOrUpdateUser(e) {
			this.loading = true;
			try {
				let data = [
					this.user.FirstName,
					this.user.LastName, this.user.Email,
					this.user?.countryCode + this.user.PhoneNumber, this.adminRoleSwitch, this.operatorRoleSwitch,

				]
				let res;
				if (this.editUser?.UserInvitationID) {
					res = await userService.updateInvitation(...data, this.editUser?.UserInvitationID);
					// this.$emit("success","Invitation saved successfully!")
				} else {
					if (this.editUser) {
						data = [...data, this.user?.selectedTimezone, this.editUser?.UserID];
						res = await userService.updateUser(...data);
						this.$emit("success", null, { refresh: true, user: this.updatedEditUser() })
						if (this.source == 'profile') {
							this.$store.commit('setUser', this.updatedEditUser());
							// notifications.success("Profile Updated Successfully!!");
						}
					} else {
						res = await userService.addUser(...data);
						this.showFreeTrialError(res);
						this.$emit("success", "Invitation successfully sent to user!", { refresh: true })
					}
				}
				this.dialogClosed();
			} catch (error) {
				this.serverError = error?.message;
				this.showFreeTrialError(error);
			}
			this.loading = false;
		},
		isPhoneNumber(evt) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = evt.key;

			if (!keysAllowed.includes(keyPressed) || evt.target.value.length > 9) {
				evt.preventDefault()
			}
		},
		validatePhoneNumber(v) {
			const phoneNumber = parsePhoneNumberFromString(this.user.countryCode + this.user.PhoneNumber, this.iso2);
			if (phoneNumber && phoneNumber.isValid()) {
				return true;
			} else {
				return false;
			}
		},
		validateEmail(v) {
			if (/.+@.+\..+/.test(v)) {
				return true;
			} else {
				return false;
			}
		},
		dialogClosed(e) {
			this.dialog = false;
			this.$emit("close")
			if (typeof this.TogglePopup != "undefined") {
				this.TogglePopup();
			}
		},
		onPhoneInput(phone, phoneObject, input) {
			if (typeof phoneObject.country != "undefined") {
				this.iso2 = phoneObject.country.iso2;
			}

			let countryCallingCode = "";
			if (typeof phoneObject.countryCallingCode != "undefined") {
				countryCallingCode = phoneObject.countryCallingCode;
			} else if (typeof phoneObject.country != "undefined") {
				countryCallingCode = phoneObject.country.dialCode;
			}

			if (countryCallingCode) {
				let splitcode = this.user.countryCode ? this.user.countryCode : countryCallingCode;
				if (this.user.countryCode == "" && typeof phoneObject.number != "undefined") {
					this.user.PhoneNumber = phoneObject.number.substring(splitcode.length + 1);
				}/*  else {
					if (this.user.countryCode != phoneObject.countryCallingCode){
						//this.user.PhoneNumber = "";
					}
				} */
				this.user.countryCode = countryCallingCode;
			}
			setTimeout(function () {
				if (!document.querySelector(".countryCodePopup")) {
					return;
				}
				/* var labelElement = document.querySelector(".phone-input-pupup .v-field-label:not(.v-field-label--floating)");
				var left = document.querySelector(".countryCodePopup").offsetLeft + document.querySelector(".countryCodePopup").offsetWidth + 10;
				labelElement.style.marginLeft =  left + "px";
				labelElement.style.display =  "block"; */
			}, 500)
		},
		CountrySelectOpen() {
			document.querySelector(".phone-input-pupup .v-input__details .v-messages").style.display = 'none';
		},
		CountrySelectClose() {
			document.querySelector(".phone-input-pupup .v-input__details .v-messages").style.display = 'block';
		},
		updatedEditUser() {
			const updatedEditUser = {
				...this.editUser,
				FirstName: this.user.FirstName,
				LastName: this.user.LastName,
				Email: this.user.Email,
				Phone: this.user?.countryCode + this.user.PhoneNumber,
				UserID: this.user.UserID,
				timezone: this.user.selectedTimezone,
				SaasAdmin: this.adminRoleSwitch,
				SaasOperator: this.operatorRoleSwitch
			};

			return updatedEditUser;
		},
		showFreeTrialError(res) {
			// if(res?.type=='FREE_TRIAL'){
			// 	if(res?.code=='admin') this.adminFreeTrialError = true;
			// 	else if(res?.code=='operator') this.operatorFreeTrialError = true;
			// }
			this.$emit("freetrialerror", res);

		}

	}
}
</script>

<style lang="scss">
.phone-input-pupup {
	& .v-field-label:not(.v-field-label--floating) {
		margin-top: -20px;
	}
}


.tab-profile {
	padding: 35px 0;
}



.v_notification {
	width: 100%;
	background: #D6FCC4;
	padding: 20px 23px 20px 27px;
	font-size: 14px;
	border-radius: 10px;
	font-size: 14px;
	line-height: 16.41px;
	color: #333333;
	min-height: 80px;
	display: flex;
	align-items: center;
	position: relative;
	top: 10px;
	margin-top: -10px;
}


.v_notification_role {
	width: 100%;
	background: #344D7A;
	padding: 10px 15px;
	font-size: 16px;
	border-radius: 10px;
	line-height: 24px;
	color: #fff;
	display: block;
	align-items: center;
	position: relative;
	;
}

.v_notification_attention {
	width: 100%;
	background: #F0D3D3;
	padding: 10px 15px;
	font-size: 16px;
	border-radius: 10px;
	line-height: 24px;
	color: #000;
	display: block;
	align-items: center;
	position: relative;
	;
}




.servererror {
	width: 100%;
	background: red;
	padding: 15px;
	border-radius: 10px;
}


.modal-actions {
	display: flex;

	@media screen and (max-width: 599px) {
		flex-direction: column;
		gap: 10px;
	}
}

.button-group {
	display: flex;
	align-items: center;
	gap: 40px;

	@media screen and (max-width: 599px) {
		gap: 20px;
	}

	& .action-btn {
		color: #2F80ED;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		span {
			opacity: 1 !important;
		}
	}
}



.v-input.error--text {
	color: #B10000 !important;
	caret-color: #B10000 !important;

	.v-input__control {
		.v-text-field__details>.error--text {
			color: #B10000 !important;
			caret-color: #B10000 !important;
		}

		.v-input__slot {
			&:before {
				color: #B10000 !important;
				caret-color: #B10000 !important;
				border-width: 2px 0 0;
			}

			&:after {
				color: #B10000 !important;
				caret-color: #B10000 !important;
				border-width: 2px 0 0;
			}

			.v-text-field__slot>.error--text {
				color: #B10000 !important;
				caret-color: #B10000 !important;
			}
		}
	}
}

.v-field__overlay {
	background-color: white !important;
}

.custom-underlined-field .v-input__control {
	border-color: green;
}

.v-application .primary--text {
	color: #1976d2 !important;
	caret-color: #1976d2 !important;
}

.vue-tel-input {
	border: 0px !important;
}

.vti__input {
	display: none !important;
}


.vti__dropdown-list {
	border: 1px solid #e4e4e7;
	border-radius: 4px;
	padding: 8px 0;
	width: 100%;

	&.below {
		top: 100%;
		height: 122px !important;
		margin-top: 1px;
	}
}

.vti__dropdown {
	padding: 0 4px 0 0;
	position: initial;

	&.open,
	&:hover {
		background-color: #fff;
	}

	& .vti__dropdown-item {
		padding: 8px 12px;
		display: flex;
		align-items: center;
		gap: 6px;
		font-size: 14px;
		gap: 6px;

		&.highlighted {
			background-color: #dbeafe;
		}
	}
}


.v-tabs--density-default {
	--v-tabs-height: 26px !important;
}

.v-tab.v-tab.v-btn {
	color: #000;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.8px;
	text-transform: capitalize;
	height: 25px !important;
	min-width: 0 !important;
	margin-top: 14px;
	margin-left: 28px;
}

.v-btn--slim {
	padding: 0 !important;
}

.disabled1 {
	color: #d7e7fd !important;
	pointer-events: none;
}

.v-card {
	overflow: hidden;
}

.v-window {
	overflow: initial !important;
}

.menu {
	height: 180px;
	margin: 1px 0 0 0 !important;

	& .menu-option {
		color: #333333;
		font-size: 13px;
		line-height: 16.41px;
		font-style: normal;
		font-weight: 400;
	}
}



.timez_p {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #333333;
	font-size: 14px;
	line-height: 16.41px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 115px;
	height: 201px;

	& .tz_lable {
		margin-bottom: 24px;
		text-align: center;
		letter-spacing: 0;
		margin-top: 80px;
	}

	& .vue-select {
		width: 100%;
		max-width: 400px;
		border-radius: 10px;

		& .control {
			min-height: 80px;
			background: #F2F2F2;
			border: none;
			border-radius: 10px;
			padding: 0 12px 0 27px;

			&.focused {
				box-shadow: none;
			}
		}

		& .value-container {
			padding: 0;
		}

		& .indicators-container {
			padding: 0;
		}

		& .dropdown-icon {
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			& path {
				fill: #7B8794;
			}
		}

		& .single-value {
			color: #333333;
			font-size: 14px;
			line-height: 16.41px;
			font-style: normal;
			font-weight: 400;
			height: 100%;
		}
	}
}




.update-modal {
	min-height: 458px;

	& .together {
		min-width: 0
	}

	& .notof_tze {
		background-color: #E3F1FF;
		width: 100%;
		padding: 20px 23px 20px 27px;
		font-size: 14px;
		border-radius: 10px;
		font-size: 14px;
		line-height: 16.41px;
		color: #333333;
		min-height: 80px;
		align-items: center;
		position: relative;
		top: 10px;
		margin-top: -10px;
	}

	& .notof_01 {
		/* background-color:#E3F1FF; */
		background-color: #344D7A;
		color: #FFFFFF;
	}
}





.deactive_tz {
	color: #e5e5e5 !important;
}



.clear-button {
	display: none !important;
}

.update-modal .email_c {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
}

.update-modal .v-tab {
	cursor: pointer !important;
	transition: none !important;
}

.update-modal .v-tab:hover {
	background-color: transparent !important;
}

.update-modal .v-ripple__container {
	display: none !important;
}

.tabs_D {
	background-color: red;
}

.tabs_D_item .v-btn__overlay {
	background-color: transparent !important;
}

.tabs_D_item .v-btn__content {
	padding-bottom: 8px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #000000;
	width: 100%;
}


.tabs_D_item .v-tab__slider {
	height: 4px !important;
}

.update-modal .v-tab.v-tab.v-btn {
	margin-top: 0px;
}

.mdi-close {
	font-size: 24px !important;
	color: black !important;
	opacity: 1 !important;
}

.vti__flag {
	margin-left: 0;
}

.admin-operator-row {
	margin-top: 28px !important;
	min-height: 110px;
}

.switch-group {
	display: flex;
	align-items: center;
	gap: 73px;

	& .switch {
		display: flex;
		align-items: center;
		gap: 16px;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		color: #000;
	}
}

.data-grid-group {
	display: flex;
	justify-content: center;
	gap: 80px;
	padding: 85px 0;

	& .data-grid {
		display: flex;
		flex-direction: column;
		align-items: center;

		& h3 {
			font-size: 16px;
			font-weight: 500;
			line-height: 16px;
			text-align: center;
			color: #7B8794;
			margin-bottom: 10px;
		}

		& h6 {
			margin-top: 38px;
			font-size: 74px;
			font-weight: 300;
			line-height: 74px;
			text-align: center;
			color: #344D7A;
		}
	}
}
.disabled-overlay {
  pointer-events: none;
  opacity: 0.3;
}
</style>