<template>
    <v-dialog class="dialog" v-model="dialog" persistent max-width="446">
        <form id="payment-form">
            <v-card>
                <v-toolbar class="modal-header">
                    <v-toolbar-title>Payment Method</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="card-text-con">
                    <div id="payment-element">
                        <!-- Elements will create form elements here -->
                    </div>
                    <div id="error-message">
                        <!-- Display error message to your customers here -->
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <button id="submit" class="text-btn btn ms-auto">SAVE</button>
                    <button type="button" @click="close" class="close-btn text-btn btn ms-2">CLOSE</button>
                </v-card-actions>
            </v-card>
        </form>
    </v-dialog>
</template>

<script>
import { VDialog, VCard, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'PaymentMethodModal',
    props: ['value', 'close'],
    components: {
        VDialog, VCard, VCardText, VCardActions
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
    }
};
</script>
<style lang="scss">
.actions {
    padding: 18px !important;
    flex-direction: row-reverse;
}

.card-text-con.wishlist-modal {
    padding: 18px 18px 0 !important;

    .wishlist-modal-text {
        color: #000;
        font-family: 'Roboto';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 166.667%;

        span {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.v-dialog>.v-card>.v-card__actions.whishlist-actions {
    padding: 0 27px 18px 18px !important;
    margin-top: 40px;
}

.btn-whishlist {
    padding: 9px 17px !important;
    border-radius: 3px !important;
    background: #34A853 !important;
    color: #fff !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;

    &:hover {
        background: #48c56a !important;
    }
}

.whishlist-actions .close-btn {
    color: #4F4F4F !important;
}
</style>