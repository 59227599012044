<template>
  <div class="c-card-group">
    <div class="c-card">
      <div class="c-card-item">
        <div class="c-card-title">Usage</div>
        <div class="c-card-content">
          <div class="c-content-row row-fill-3">
            <div>Admins</div>
            <div>Managing Operators</div>
            <div class="c-value">{{ adminCount }}</div>
          </div>
          <div class="c-content-row row-fill-3">
            <div>Operators</div>
            <div>Managing contacts; Setting geofence messages</div>
            <div class="c-value">{{ operatorCount }}</div>
          </div>
          <div class="c-content-row row-fill-3">
            <div class="c-icon-text">
              <div>Receivers</div>
              <IconQuestionMark />
            </div>
            <div>Hearing geofence messages in the field</div>
            <div class="c-value">{{ receiverCount }}</div>
          </div>
          <div class="c-bottom-content">
            <div class="c-content-row row-fill-3-1">
              <div class="c-icon-text">
                <div>Geofence Messages</div>
                <IconQuestionMark />
              </div>
              <div class="d-flex align-items-center col-span-2">
                <div style="margin-top: 20px;" class="a-slider account-slider"
                  :class="{ flip: isUnlimited, unflip: !isUnlimited, 'c-disabled': (underFreeTrial || (!underFreeTrial && !isAccountActive)) }">
                  <v-slider v-model="sliderIndex" :min="0" :max="customValues.length - 1" :step="1" strict
                    @start="onSliderDragStart" @end="onSliderDragEnd" show-ticks="always" thumb-label="always"
                    thumb-size="16" tick-size="0" track-color="#BDBDBD" track-fill-color="#828282">
                    <template v-slot:thumb-label="{ modelValue }">
                      {{ customValues[modelValue] }}
                    </template>
                  </v-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-card card-right-full">
      <div class="c-card-item">
        <div class="c-card-title">Monthly Rate</div>
        <div class="c-card-content">
          <div class="c-content-row">
            <div>${{ perAdminPrice }} per Admin</div>
            <div class="c-value">${{ formatNumber(adminPrice) }}</div>
          </div>
          <div class="c-content-row">
            <div>${{ perOperatorPrice }} per Operator</div>
            <div class="c-value">${{ formatNumber(operatorPrice) }}</div>
          </div>
          <div class="c-content-row">
            <div>${{ perReceiverPrice }} per Receiver</div>
            <div class="c-value">${{ formatNumber(receiverPrice) }}</div>
          </div>
          <div class="c-content-row">
            <div v-if="!isProjectedBillingUpdated">${{ formatNumber(perMessagePrice) }} per Message</div>
            <div v-if="isProjectedBillingUpdated">${{ formatNumber(updatedPerMessagePrice) }} per Message</div>
            <div v-if="!isProjectedBillingUpdated" class="c-value">${{ formatNumber(messagePrice) }}</div>
            <div v-if="isProjectedBillingUpdated" class="c-value">${{ formatNumber(updatedMessagePrice) }}</div>
          </div>
          <div class="c-bottom-content border-top">
            <div class="c-content-row">
              <div class="c-icon-text">
                <div>$0.95 per Receiver</div>
                <IconQuestionMark />
              </div>
              <div class="c-value">$0</div>
            </div>
            <div class="c-content-row">
              <div></div>
              <div class="c-value">$0</div>
            </div>
            <div class="c-content-row">
              <div></div>
              <div class="c-value">$0</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-card">
      <div class="c-card-item">
        <div class="c-card-title">Add-ons</div>
        <div class="c-card-content">
          <div class="c-content-row row-fill-3-2">
            <div>Activity Feed</div>
            <div></div>
            <div class="c-switch-row">
              <div class="c-icon-text">
                <IconQuestionMark />
                <div class="c-disabled">Activity History</div>
              </div>
              <SwitchLg class="c-disabled" v-model="switch1" />
            </div>
          </div>
          <div class="c-content-row row-fill-3-2">
            <div>Translations*</div>
            <div class="c-switch-row">
              <div class="c-icon-text">
                <IconQuestionMark />
                <div class="c-disabled">European languages</div>
              </div>
              <SwitchLg class="c-disabled" v-model="switch5" />
            </div>
            <div class="c-switch-row">
              <div class="c-icon-text">
                <IconQuestionMark />
                <div class="c-disabled">World languages</div>
              </div>
              <SwitchLg class="c-disabled" v-model="switch2" />
            </div>
          </div>
          <div class="c-bottom-content">
            <div class="c-content-row row-fill-3-2">
              <div>Analytics</div>
              <div class="c-switch-row">
                <div class="c-icon-text">
                  <div class="c-disabled">Operator View</div>
                </div>
                <SwitchLg class="c-disabled" v-model="switch3" />
              </div>
              <div class="c-switch-row">
                <div class="c-icon-text">
                  <div class="c-disabled">Global View</div>
                </div>
                <SwitchLg class="c-disabled" v-model="switch4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-card-footer" :style="footerStyle">
      <div class="c-card-footer-left">
        <div style="display: flex;gap: 12px;;">
          <h6 v-if="!isProjectedBillingUpdated" :style="{ color: leftMessageColor }">{{ leftMessage }}</h6>
          <h6 style="color: #3E9B38;" v-if="isProjectedBillingUpdated">
            The projected billing total has changed.</h6>
          <div v-if="isTooltip">
            <!-- <IconQuestionMark /> -->
            <span>
              <IconQuestionMarkSm />
              <v-tooltip activator="parent" location="top">
                <div style="padding-block: 8px;">
                  <div class="tooltip-title" v-text="tooltipTitle"></div>
                  <p class="tooltip-message" v-html="tooltipMessage"></p>
                </div>
                </v-tooltip>
            </span>
          </div>
        </div>

        <div style="display: flex;gap: 12px;">
          <button v-if="!isProjectedBillingUpdated && !isPaymentMethodAdded" type="button" @click="onPaymentMethodClick"
            class="c-btn-payment">Payment Method</button>
          <button v-if="!isProjectedBillingUpdated && isPaymentMethodAdded" type="button" @click="openBilling"
            class="c-btn-outline">Billing</button>
          <button v-if="!isProjectedBillingUpdated && isPaymentMethodAdded && !isAccountActive && !isAccountDeactivated"
            type="button" @click="activateAccount" class="c-btn-activate">Activate</button>
          <button v-if="isProjectedBillingUpdated" type="button" @click="revertProjectedBilling"
            class="c-btn-outline">Cancel</button>
          <button v-if="isProjectedBillingUpdated" type="button" @click="showUpdateMessageConfirmation = true"
            class="c-btn-update-usage">Update
            Usage</button>
        </div>
      </div>

      <div class="c-card-footer-right">
        <div class="c-icon-text">
          <h5>Projected Monthly Bill</h5>
          <IconQuestionMark />
        </div>
        <div class="c-value" v-if="!isProjectedBillingUpdated">${{ formatNumber(data?.ProjectedPrice) }}</div>
        <div class="c-value" v-if="isProjectedBillingUpdated">${{ formatNumber(updatedProjectedPrice) }}</div>
      </div>
    </div>
  </div>

  <PaymentMethodModal v-model="paymentMethodModalVisible" :close="closePaymentMethodModal" />
  <ConfirmationCustom v-model="showUpdateMessageConfirmation" @closed="showUpdateMessageConfirmation = false"
    :onconfirm="(data) => confirmUpdateMessages(data)" title="Confirmation">
    <div>The current usage has been changed.</div> <br>
    <div>{{ customValues?.at(sliderIndex) ?? 0 }} Geofence Messages can now be concurrently active.</div> <br>
    <div>This changes the monthly billing rate.</div>
  </ConfirmationCustom>

  <AlertDialog v-model="showMessagePackFreeTrialError" @closed="showMessagePackFreeTrialError = false"
    title="Attention">
    <div>During this period of free usage, you can have up to twenty-five concurrent Geofence Messages.</div> <br>
    <div>To have more than twenty five, activate your paid account now.</div> <br>
    <div>If you are not the Account Holder, request that they convert to a paid account. </div>
  </AlertDialog>

</template>

<script setup>
import SwitchLg from '@/components/common/SwitchLg.vue';
import IconQuestionMark from '@/components/icons/IconQuestionMark.vue';
import IconQuestionMarkSm from '@/components/icons/IconQuestionMarkSm.vue';
import AlertDialog from '@/components/modals/AlertDialog.vue';
import ConfirmationCustom from '@/components/modals/ConfirmationCustom.vue';
import PaymentMethodModal from '@/components/modals/PaymentMethodModal.vue';
import userService from '@/services/userService';

import { ref, computed, onMounted, nextTick, watch } from 'vue';

const switch1 = ref(false);
const switch2 = ref(false);
const switch3 = ref(false);
const switch4 = ref(false);
const switch5 = ref(false);

const data = ref({});
const paymentMethodModalVisible = ref(false);
let stripe = null;
let stripeElements = null;
let cardElement = null;
const options = {
  currency: 'usd',
};

let clientSecret = null;

let adminCount = ref(0);
let operatorCount = ref(0);
let receiverCount = ref(0);
let messageCount = ref(0);
let perAdminPrice = ref(0);
let perOperatorPrice = ref(0);
let perReceiverPrice = ref(0);
let perMessagePrice = ref(0);
let adminPrice = ref(0);
let operatorPrice = ref(0);
let receiverPrice = ref(0);
let messagePrice = ref(0);
const billingUrl = ref(null);
const isPaymentMethodAdded = ref(false);
const isAccountActive = ref(false);
const isAccountDeactivated = ref(false);
const underFreeTrial = ref(false);
const freeTrialDays = ref(0);
const isProjectedBillingUpdated = ref(false);
const leftMessage = ref("");
const leftMessageColor = ref("");
const updatedProjectedPrice = ref(0);
const updatedPerMessagePrice = ref(0);
const updatedMessagePrice = ref(0);
const isFreeTrialStarted = ref(false);
const isFreeTrialEnded = ref(false);
const isTooltip = ref(false);
const tooltipTitle = ref("")
const tooltipMessage = ref("")
const showUpdateMessageConfirmation = ref(false);
const showMessagePackFreeTrialError = ref(false);
const freeTrialRes = ref({});
const footerStyle = computed(() => {
  let backgroundColor = '#fff';
  if(freeTrialRes.value?.backgroundColor){
    backgroundColor = freeTrialRes.value?.backgroundColor
  }
  // if (!isAccountActive.value) {
  //   backgroundColor = "#EAF2FC"
  // }
  // if (isAccountDeactivated.value || !isPaymentMethodAdded.value || (isPaymentMethodAdded.value && isAccountActive.value)) {
  //   backgroundColor = "#fff"
  // }
  // if (isProjectedBillingUpdated.value) {
  //   backgroundColor = "#ECFFDD"
  // }
  return {
    backgroundColor: backgroundColor,
  };
})

const customValues = Array.from(
  { length: Math.floor((100 - 25) / 5) + 1 },
  (_, i) => 25 + i * 5
);
// Slider index to track position
const sliderIndex = ref(0);

const isUnlimited = computed(() => sliderIndex.value === customValues.length - 1);


function onSliderDragStart() {
  return false;
}
function onSliderDragEnd() {
  if (customValues?.at(sliderIndex.value) == messageCount.value) {
    isProjectedBillingUpdated.value = false;
    return;
  }
  getNewProjectedPrice()
}

function initializeStripe() {
  let stripeKey = "pk_test_51QUS5LGDIg03dSHf2PhKrxz9fbd5jtHjIiSF4pSZhTtdM8AMDsNToIt2fWNiWHNbLsOcp9MrwVvqVGzUSeVxHk6a00kIjX3uR9";
  if (!window.Stripe) {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.onload = () => {
      // eslint-disable-next-line
      stripe = Stripe(stripeKey);
      console.log('Stripe initialized:', stripe);
    };
    document.head.appendChild(script);
  } else {
    // eslint-disable-next-line
    stripe = Stripe(stripeKey);
    console.log('Stripe initialized:', stripe);
  }
}

async function createStripeElements() {
  if (!clientSecret) {
    let res = await userService.getStripeToken();
    clientSecret = res.clientSecret;

    if (res.message && res?.message == "Payment method already exists") {
      await loadFreeTrialStatus();
      return;
    }
  }
  if (!cardElement) {
    const paymentElementOptions = { layout: 'tabs' };
    stripeElements = stripe.elements({ ...options, clientSecret: clientSecret });
    cardElement = stripeElements.create('payment', paymentElementOptions);
  }
}

async function onPaymentMethodClick() {
  await createStripeElements();
  if (!cardElement) return;

  paymentMethodModalVisible.value = true;
  await nextTick();
  cardElement.mount('#payment-element');
}

watch(paymentMethodModalVisible, async () => {
  await nextTick();
  const form = document.getElementById('payment-form');
  const submitBtn = document.getElementById('submit');

  const handleError = (error) => {
    const messageContainer = document.querySelector('#error-message');
    messageContainer.textContent = error.message;
    submitBtn.disabled = false;
  }

  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    if (submitBtn.disabled) {
      return;
    }

    submitBtn.disabled = true;

    const { error: submitError } = await stripeElements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }


    let res = await stripe.confirmSetup({
      elements: stripeElements,
      clientSecret,
      confirmParams: {
        return_url: 'https://sig2.app',
      },
      redirect: "if_required"
    });


    if (res.error) {
      handleError(res?.error);
    } else {
      closePaymentMethodModal()
      isPaymentMethodAdded.value = true;
      if (!isFreeTrialStarted.value) {
        leftMessage.value = `Start 14-day free period, or activate your account.`;
      } else if (isFreeTrialStarted.value && !isFreeTrialEnded.value) {
        leftMessage.value = `${freeTrialDays.value} days free remaining. Activate your account.`;
      } else if (isFreeTrialStarted.value && isFreeTrialEnded.value) {
        leftMessage.value = `14-day free period has ended. Activate your account.`;
      }
      leftMessageColor.value = "#344D7A";
      setTimeout(() => {
        loadFreeTrialStatus();
      }, 2000)
    }
  });

})

function closePaymentMethodModal() {
  paymentMethodModalVisible.value = false;
}

function showFreeTrialError(res) {
  if (res?.type == 'FREE_TRIAL') {
    if (res?.code == 'messages') {
      showUpdateMessageConfirmation.value = false;
      showMessagePackFreeTrialError.value = true;
      revertProjectedBilling();
    }
  }
}

async function loadUsage() {
  let res = await userService.getUserUsage();
  data.value = res;

  let usage = res?.Usage;
  adminCount.value = usage?.adminCount ?? 0;
  operatorCount.value = usage?.operatorCount ?? 0;
  receiverCount.value = usage?.receiversCount ?? 0;
  messageCount.value = usage?.messageCount ?? 0;

  updateSliderValue(messageCount.value);
  let perUnitPrices = res?.PerUnit;
  perAdminPrice.value = perUnitPrices?.admin ?? 0;
  perOperatorPrice.value = perUnitPrices?.operator ?? 0;
  perReceiverPrice.value = perUnitPrices?.receivers ?? 0;
  perMessagePrice.value = perUnitPrices?.message ?? 0;

  let prices = res?.Price;
  adminPrice.value = prices?.AdminPrice ?? 0;
  operatorPrice.value = prices?.OperatorPrice ?? 0;
  receiverPrice.value = prices?.ReceiversPrice ?? 0;
  messagePrice.value = prices?.MessagePrice ?? 0;
}

function updateSliderValue(num) {
  sliderIndex.value = (num - 25) / 5;
}

function formatNumber(number) {
  return Number(number).toFixed(2);
}


async function loadFreeTrialStatus() {
  let res = await userService.getFreeTrialStatus();
  freeTrialRes.value = res;
  isPaymentMethodAdded.value = res?.isPaymentMethod;
  isAccountActive.value = res?.isAccountActive;
  isAccountDeactivated.value = res?.isAccountDeactivated;
  underFreeTrial.value = res?.underFreeTrial;
  freeTrialDays.value = res?.days;
  leftMessage.value = res?.message;
  leftMessageColor.value = res?.color;

  isFreeTrialStarted.value = res?.isFreeTrialStarted;
  isFreeTrialEnded.value = res?.isFreeTrialEnded;
  isTooltip.value = res?.isTooltip;
  tooltipTitle.value = res?.toolTipTitle;
  tooltipMessage.value = res?.toolTipMessage?.replace("\n","<br/>");

  if (isAccountActive.value) underFreeTrial.value = false;

  // isPaymentMethodAdded.value = true;
  // isAccountActive.value = false;
  // underFreeTrial.value = false;
  // leftMessage.value = " this ajf;ajflajf ; f a;sfj akfj alsjkf ;alj f";


}

async function openBilling() {
  if (!billingUrl.value) {
    let invoice = await userService.getBillingUrl();
    billingUrl.value = invoice?.data;
  }
  window.open(billingUrl.value, '_blank');
}

async function activateAccount() {
  let data = await userService.activateAccount();
  await loadFreeTrialStatus()
}

function revertProjectedBilling() {
  isProjectedBillingUpdated.value = false;
  updateSliderValue(messageCount.value);
}

async function getNewProjectedPrice() {
  try {
    let res = await userService.getNewProjectedPrice(data.value?.ProjectedPrice, messagePrice.value, customValues?.at(sliderIndex.value))
    updatedProjectedPrice.value = res?.ProjectedPrice
    updatedPerMessagePrice.value = res?.messagePerUnit;
    updatedMessagePrice.value = res?.messagesPrice;
    isProjectedBillingUpdated.value = true;
  } catch (error) {
    showFreeTrialError(error)
  }
}

async function confirmUpdateMessages() {
  try {
    let messages = customValues?.at(sliderIndex.value)
    let res = await userService.updateMessages(messages);
    console.log(res);
    await loadUsage();
    showUpdateMessageConfirmation.value = false;
    isProjectedBillingUpdated.value = false;
  } catch (error) {
    showFreeTrialError(error)
  }
}

onMounted(() => {
  initializeStripe()
  loadUsage();
  loadFreeTrialStatus();
  // getInvoice();
})
</script>

<style lang="scss" scoped>
.c-card-group {
  display: grid;
  grid-template-columns: 1fr 440px;
  column-gap: 60px;
  row-gap: 50px;
  margin-bottom: 60px;
}

.c-card-column-3 {
  display: grid;
  grid-template-columns: 266fr 272fr 202fr;
}

.c-card-column-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.card-right-full {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
}

.content-type-1 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;

  & .btn-group {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    height: 85px;
  }
}

.c-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 60px;
  border-bottom: 1px solid #E0E0E0;
  line-height: 20px;

  &.row-fill-3 {
    display: grid;
    grid-template-columns: 235px 370px auto;
    justify-content: unset;
  }

  &.row-fill-3-1 {
    display: grid;
    grid-template-columns: 204px auto;
    justify-content: unset;
    height: 80px;
  }

  &.row-fill-3-2 {
    display: grid;
    grid-template-columns: 201px 260px auto;
    justify-content: space-between;
  }

  & .c-value {
    font-size: 24px;
    line-height: 20px;
    text-align: right;
  }
}

.c-bottom-content {
  margin-top: auto;

  & .c-content-row:last-child {
    border-bottom: none;
  }
}

.switch-group {
  margin-top: 27px;
}

.c-card {
  background-color: #fff;
  padding: 33px 30px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  & .c-card-title {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    padding-bottom: 11px;
    border-bottom: 1px solid #E0E0E0;
    font-weight: 500;
  }

  & .c-card-item {
    display: flex;
    flex-direction: column;
    flex: 1;

    & .c-card-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}


.c-icon-text {
  display: flex;
  align-items: center;
  gap: 10px;
}


.c-switch-row {
  display: grid;
  text-align: right;
  grid-template-columns: 158px 46px;
  white-space: nowrap;
  gap: 30px;

  & .c-icon-text {
    justify-content: flex-end;
  }
}

.c-card-footer {
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #fff;
  padding-left: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  & .c-card-footer-left {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    & h6 {
      font-size: 20px;
      // color: #4992E6;
      line-height: 1;
      font-weight: 500;
    }

    & .c-btn-payment {
      width: 184px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background-color: #4066CF;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }

    & .c-btn-outline {
      padding-inline: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background-color: transparent;
      color: #344D7A;
      font-size: 16px;
      font-weight: 500;
      border: 2px solid #344D7A;
      transition: background-color 0.3s, color 0.3s;
    }

    // & .c-btn-billing:hover {
    //   background-color: #344D7A;
    //   color: #fff;
    // }

    & .c-btn-activate {
      padding-inline: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background-color: #344D7A;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }

    & .c-btn-update-usage {
      padding-inline: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background-color: #5CAF0F;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
  }

  & .c-card-footer-right {
    padding: 30px;
    width: 440px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h5 {
      font-size: 16px;
      line-height: 1;
      font-weight: 500;
    }
  }

  & .c-value {
    font-size: 24px;
    line-height: 20px;
    text-align: right;
  }
}

.c-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.tooltip-title{
  font-weight: 600;
  font-size: 14px;
    font-family: Roboto;
}
.tooltip-message{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto;
}
</style>
